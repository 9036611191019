import React from "react";

const TermsAndConditions = () => {
  return (
    <>
      <div className="terms-and-condition container">
        <h1>Terms And Conditions</h1>
        <h3 className="sub-heading-style">Last Updated: Jan 24, 2025</h3>
        <div className="mt-3 mb-5">
          {/* <h2 className="mb-3">What is Lorem Ipsum?</h2> */}
          <p>
            Welcome to Insight! By downloading, installing, or using our app,
            you agree to comply with and be bound by the following Terms and
            Conditions. Please read them carefully. If you do not agree to these
            terms, please do not use the app.
          </p>
          <br />
          <h3>1. Acceptance of Terms </h3>
          <p>
            By accessing or using Insight, you confirm that you are at least 18
            years old and have the legal capacity to enter into these Terms. If
            you are using the app on behalf of a company or organization, you
            represent that you have the authority to bind that entity to these
            Terms.
          </p>
          <br />
          <h3>2. Services Provided </h3>
          <p>Insight is designed to help people connect by:</p>
          <ul className="ms-4">
            <li className="list-style-dot">
              Sending connection requests to other users.
            </li>
            <li className="list-style-dot">Engaging in real-time chats.</li>
            <li className="list-style-dot">
              Discovering and interacting with other features we may add from
              time to time.
            </li>
            <li></li>
          </ul>
          <p className="mt-2">
            We reserve the right to modify, suspend, or discontinue any part of
            the app at any time without prior notice.
          </p>

          <br />
          <h3>3. User Accounts</h3>
          <div className="mt-2 ms-4">
            <h6>Registration :</h6>
            <p>
              create an account. You agree to provide accurate and complete
              information during registration and to keep this information
              updated.
            </p>
            <h6>Account Security :</h6>
            <p>
              You are responsible for maintaining the confidentiality of your
              account credentials. Any activity conducted through your account
              is your responsibility.
            </p>
            <h6>Termination :</h6>
            <p>
              We reserve the right to suspend or terminate your account if you
              violate these Terms.
            </p>
          </div>
          <br />
          <h3>4. Acceptable Use Policy </h3>
          <p>When using Insight, you agree to:</p>
          <ul className="ms-4 mt-3">
            <li className="list-style-dot">
              Use the app only for lawful purposes.
            </li>
            <li className="list-style-dot">
              Respect the privacy and rights of other users.
            </li>
            <li className="list-style-dot">
              Refrain from sharing or uploading inappropriate, offensive, or
              illegal content.
            </li>
            <li>Avoid spamming, harassing, or impersonating other users.</li>
          </ul>
          <p>
            We reserve the right to remove content and/or restrict access to
            users who violate this policy.
          </p>
          <br />
          <h3>5. Privacy</h3>
          <p>
            Your privacy is important to us. Please refer to our Privacy
            Policy to understand how we collect, use, and protect your
            information.
          </p>
          <br />
          <h3>6. Intellectual Property</h3>
          <p>
            All intellectual property rights in the app, including but not
            limited to the design, text, graphics, logos, and software, are
            owned by Insight or its licensors. You may not copy, modify,
            distribute, or reverse-engineer any part of the app without prior
            written consent.
          </p>
          <br />
          <h3>7. Disclaimers</h3>
          <ul className="ms-4 mt-3">
            <li className="list-style-dot">
              Insight is provided on an "as-is" and "as-available" basis without
              any warranties, express or implied.
            </li>
            <li className="list-style-dot">
              We do not guarantee uninterrupted or error-free service.
            </li>
            <li className="list-style-dot">
              We are not responsible for the actions or content of other users.
            </li>
          </ul>
          <br />
          <h3>8. Limitation of Liability</h3>
          <p>
            To the fullest extent permitted by law, Insight and its affiliates
            shall not be liable for any indirect, incidental, special,
            consequential, or punitive damages arising from your use of the app.
          </p>
          <br />
          <h3>9. Changes to Terms</h3>
          <p>
            We may update these Terms from time to time. Changes will be
            effective upon posting the updated Terms within the app or on our
            website. Your continued use of the app signifies your acceptance of
            the revised Terms.
          </p>
          <br />
          <h3>10. Contact Us</h3>
          <p>
            If you have any questions about these Terms, please contact us at:
          </p>
          <br />
          <h3>Email:</h3>
          <p>info@insight.com</p>
          <div className="mt-2">
            <p>
              Thank you for using Insight! We’re excited to help you connect and
              grow your network.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export { TermsAndConditions };
