import React from "react";
const Privacy = () => {
  return (
    <>
      <div className="privacy-policy terms-and-condition container">
        <h1>Privacy Policy</h1>
        <div className="mt-3 mb-5">
          {/* <h2 className="mb-3">What is Lorem Ipsum?</h2> */}
          <p>
            Insight is committed to protecting your privacy. This Privacy Policy
            explains how we collect, use, disclose, and safeguard your
            information when you use our app and services. Please read this
            policy carefully. If you do not agree with the terms, please do not
            use our services.
          </p>
          <br />
          <h3>1. Information We Collect</h3>
          <p>We may collect the following types of information:</p>
          <br />
          <h3 className="option-alphabat">a. Personal Information</h3>
          <p>Information you provide directly to us, such as:</p>
          <ul className="ms-4">
            <li className="list-style-dot">
              Your name, email address, phone number, profile picture, and other
              account details.
            </li>
            <li className="list-style-dot">Engaging in real-time chats.</li>
            <li className="list-style-dot">
              Information you provide when sending connection requests or
              messages.
            </li>
          </ul>
          <br />
          <h3 className="option-alphabat">b. Usage Data</h3>
          <ul className="ms-4">
            <li className="list-style-dot">
              Interaction history, including chats and connection requests.
            </li>
            <li className="list-style-dot">
              Device information, such as your device model, operating system,
              and unique identifiers.
            </li>
            <li className="list-style-dot">
              Log data, including IP address, browser type, and time of access.{" "}
            </li>
          </ul>
          <h3 className="option-alphabat">c. Location Data</h3>
          <p>
            If you enable location services, we may collect your geolocation to
            improve app functionality (e.g., to suggest nearby users).
          </p>
          <h3 className="option-alphabat">
            d. Cookies and Similar Technologies
          </h3>
          <p>
            We may use cookies or similar tracking technologies to enhance your
            experience and analyze app usage.
          </p>
          <br />
          <h3>2. How We Use Your Information</h3>
          <p>We use the collected information to:</p>
          <ul className="ms-4 mt-3">
            <li className="list-style-dot">
              Provide, operate, and maintain the app.
            </li>
            <li className="list-style-dot">
              Facilitate user connections and interactions.
            </li>
            <li className="list-style-dot">
              Send notifications, updates, or promotional messages (with your
              consent).
            </li>
            <li>Monitor app performance and resolve technical issues.</li>
            <li>
              Comply with legal obligations and enforce our Terms and
              Conditions.
            </li>
          </ul>
          <p>
            We reserve the right to remove content and/or restrict access to
            users who violate this policy.
          </p>
          <br />
          <h3>3. How We Share Your Information</h3>
          <p>
            We do not sell your information. However, we may share your
            information with:
          </p>
          <h3 className="option-alphabat">a. Other Users</h3>
          <p>
            When you connect with other users, they may see your profile
            details, including name, profile picture, and other shared content.
          </p>
          <h3 className="option-alphabat">b. Service Providers</h3>
          <p>
            Third-party vendors assisting with app functionality, analytics, or
            customer support may access your information as needed to perform
            their services.
          </p>
          <h3 className="option-alphabat">c. Legal Obligations</h3>
          <p>
            We may disclose your information if required by law or in response
            to valid legal requests.
          </p>
          <h3 className="option-alphabat">d. Business Transfers</h3>
          <p>
            In the event of a merger, acquisition, or sale of assets, your
            information may be transferred to the new entity.
          </p>
          <br />
          <h3>4. Your Choices</h3>
          <p>
            All intellectual property rights in the app, including but not
            limited to the design, text, graphics, logos, and software, are
            owned by Insight or its licensors. You may not copy, modify,
            distribute, or reverse-engineer any part of the app without prior
            written consent.
          </p>
          <br />
          <h3>5. Data Security</h3>
          <p>
          We implement appropriate technical and organizational measures to safeguard your information. However, no method of transmission or storage is 100% secure, and we cannot guarantee absolute security.
          </p>
          <br />
          <h3>6. Children’s Privacy</h3>
          <p>
          Insight is not intended for users under the age of 18. We do not knowingly collect personal information from children. If we become aware that we have inadvertently collected such information, we will take steps to delete it.
          </p>
          <br />
          <h3>7. Changes to This Privacy Policy</h3>
          <p>We may update this Privacy Policy from time to time. Any changes will be posted within the app or on our website, along with the updated “Last Updated” date. Continued use of the app signifies your acceptance of the updated policy.</p>
          <br />
         
          <h3>8. Contact Us</h3>
          <p>
            If you have any questions about these Terms, please contact us at:
          </p>
          <br />
          <h3>Email:</h3>
          <p>info@insight.com</p>
          <div className="mt-2">
            <p>
              Thank you for using Insight! We’re excited to help you connect and grow your network.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export { Privacy };
